@import "../_defFuncValues.scss";
@import "../_function.scss";

$catalog-search-btn-size: toEm(39px);

.logo-search-pnl {
    display: table;
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    padding-top: 16px;

    .city-select-input {
        width: 100%;
    }

    .logo-cell{
        display: table-cell;
        vertical-align: middle;
    }

    .logo-cell {
        text-align: center;
        font-size: 0;
        line-height: 0;
    }

    .search-cell {
        display: inline-block;
        padding-left: toEm(25px);
    }
}

.catalog-select-placeholder {
    box-sizing: border-box;

    > div {
        width: 48%;
        display: inline-block;
    }

    .catalog-select-placeholder {
        padding-right: 1%;
        float: left;
    }

    .catalog-search-placeholder {
        padding-left: 1%;
        float: right;
        & input[type="search"]{
            padding:0.867rem;
        }
    }
}

.catalog-search-btn {
    position: absolute;
    width: $catalog-search-btn-size;
    height: $catalog-search-btn-size;
    display: inline-block;
    top: calc( 50% - ( #{$catalog-search-btn-size} / 2 ));
    right: 7px;
    border-radius: toEm(5px);
    margin: auto 0;

    span {
        width: toEm(16px);
        height: toEm(21px);
        position: absolute;
        display: inline-block;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

.catalog-select-placeholder .good-select-block select {
    height: toEm(48px);
}

.carousel-main-item img {
    border-radius: 0;
    width: 100% !important;
}
.carousel-main-list{
    font-size: 0;
}
.carousel-inner--min-height-mobile{
    min-height: 170px;
}