.prodList-section,
.sale-section,
.novelty-section,
.mainpage-products {
    overflow: hidden;
}

.mainpage-products__header,
.mainpage-products__all {
    text-align: center;
}

.mainpage-products__header {
    /*margin-bottom: 16px;*/
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.66667em 1em;
}

.mainpage-products__header-title {
    font-size: 20px;
}

.mainpage-products__header-link {
    text-transform: uppercase;
    font-size: 20px;
}

.mainpage-products__all-link {
    display: inline-block;
    font-size: 13px;
}

.prodList-section__content,
.sale-section__content,
.novelty-section__content,
.mainpage-products__content {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: flex-start;
}

.mainpage-products__content-item {
    padding-right: 13px;
    padding-left: 13px;
    border: 0;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
}